<template>
  <div class="contrainer minWidth1000">
    <div class="contrainer-left">
      <custom-actions class="card" title="所属基站">
        <el-tree
          default-expand-all
          :expand-on-click-node="false"
          :data="deptList"
          :highlight-current="true"
          :props="defaultProps"
          @node-click="handleNodeClick"
        >
          <template v-slot="{ data }">
            <div class="org-item">
              <span>{{data.deptName}}</span>
              <img :class="{ visible: !!data.msg }" src="@/assets/gaojing.png" alt="">
            </div>
          </template>
        </el-tree>
      </custom-actions>
    </div>
    <div class="contrainer-right">
      <custom-actions>
        <template #header>
          <el-button type="primary" @click="toSetting">告警语音配置</el-button>
          <el-button type="primary" @click="exportFile">导出</el-button>
        </template>
        <el-form inline label-width="100px">
          <el-form-item label="设备串号">
            <el-input v-model="searchForm.deviceNum" placeholder="设备串号" />
          </el-form-item>
          <el-form-item label="告警类型">
            <el-select v-model="searchForm.msgType" clearable @change="msgTypeChange">
              <el-option :label="item.dicValue" :value="item.dicCode" v-for="(item, index) of msgTypeList" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="searchForm.msgType === '1'" label="告警子类型">
            <el-select v-model="searchForm.msg" clearable>
              <el-option label="瞬时短路" value="瞬时短路"></el-option>
              <el-option label="漏电" value="漏电"></el-option>
              <el-option label="拉弧故障" value="拉弧故障"></el-option>
              <el-option label="短延时短路" value="短延时短路"></el-option>
              <el-option label="过载" value="过载"></el-option>
              <el-option label="过电压" value="过电压"></el-option>
              <el-option label="欠电压" value="欠电压"></el-option>
              <el-option label="超温" value="超温"></el-option>
              <el-option label="缺相" value="缺相"></el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="告警日期">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change='changeTime'
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          </el-form-item>
        </el-form>
      </custom-actions>

      <el-card shadow="never" class="my-table" :body-style="{ padding: '15px' }">
        <el-tabs class="tabs" v-model="searchForm.realTime" @tab-click="onSearch">
          <el-tab-pane label="偷电告警" name="1"></el-tab-pane>
          <el-tab-pane label="其他电力告警" name="2"></el-tab-pane>
        </el-tabs>
        <el-table stripe size="mini" border v-loading="loading" :data="tableData">
          <el-table-column min-width="150" label="所属基站" prop="stationName"></el-table-column>
          <el-table-column min-width="150" label="设备串号" prop="deviceNum"></el-table-column>
          <el-table-column min-width="150" label="告警类型" prop="msgTypeName"></el-table-column>
          <el-table-column min-width="150" label="告警时间" prop="ctime"></el-table-column>
          <el-table-column min-width="150" label="解除时间" prop="relieveTime"></el-table-column>
          <el-table-column min-width="200" show-overflow-tooltip label="消息内容" prop="msg"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip label="告警状态">
            <template v-slot="{ row }">{{row.msgState | msgStateName}}</template>
          </el-table-column>
          <el-table-column label="操作" v-if="searchForm.realTime === '1'">
            <template v-slot="{ row }">
              <el-button type="text" :disabled="row.msgState !== '0'" @click="confirm(row.msgId)">确认</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="mt10"
          :current-page.sync="page"
          background
          layout="total, prev, pager, next"
          :total="total"
          @current-change="loadData"
        >
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
import { filterEmpyKey } from '@/utils/utils.js'
export default {
  name: 'AlarmList',
  data () {
    return {
      tableData: [],
      page: 1,
      total: 0,
      loading: false,
      dateRange: [],
      searchForm: {
        realTime: '1',
        stationId: null,
        deviceNum: null,
        msgType: null,
        msg: null,
        startTime: null,
        endTime: null
      },
      deptList: [],
      defaultProps: {
        children: 'childNode',
        label: 'deptName',
        value: 'deptId'
      },
      msgTypeList: []
    }
  },
  computed: {
  },
  filters: {
    msgStateName (value) {
      switch (value) {
        case '0': return '待确认'
        case '1': return '待恢复'
        case '2': return '已恢复'
        default: return '未知'
      }
    }
  },
  watch: {
  },
  created () {
    this.loadData()
    this.getDept()
    this.getMsgType()
  },
  methods: {
    changeTime (arr) {
      if (arr && arr.length > 0) {
        this.searchForm.startTime = arr[0]
        this.searchForm.endTime = arr[1]
      } else {
        this.searchForm.startTime = null
        this.searchForm.endTime = null
      }
    },
    toSetting () {
      this.$router.push('/alarm/setting')
    },
    getMsgType () {
      this.$axios
        .get(this.$apis.common.selectDicByList, {
          params: {
            dicType: 'msgType'
          }
        })
        .then(res => {
          this.msgTypeList = res.msgType || []
        })
    },
    msgTypeChange (value) {
      console.log('变化了')
      this.searchForm.msg = null
    },
    loadData () {
      this.loading = true
      this.$axios
        .get(this.$apis.device.selectMsgByPage, {
          params: {
            page: this.page,
            ...this.searchForm
          }
        })
        .then(res => {
          this.tableData = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleNodeClick (data) {
      console.log(data)
      this.searchForm.stationId = data.deptId
      this.loadData()
    },
    getDept () {
      this.$axios.get(this.$apis.system.selectSysDeptByList).then(res => {
        if (res) {
          this.deptList = res
        }
      })
    },
    confirm (msgId) {
      this.$confirm('是否确认该告警', '提示', {
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.device.confirmMsg, {
          msgId
        }).then(() => {
          this.loadData()
        })
      })
    },
    onSearch () {
      this.page = 1
      this.loadData()
    },
    exportFile () {
      const form = filterEmpyKey(this.searchForm)

      const token = this.$store.state?.token
      let str = ''
      for (var key in form) {
        str += key + '=' + (form[key] || '') + '&'
      }
      str = str.substr(0, str.length - 1)
      const url = `${this.$apis.device.exportMsg}?token=${token}`
      window.location.href = url + (str ? '&' + str : str)
    }
  }
}
</script>

<style scoped lang="scss">
.tabs {
  margin-top: -10px;
}

.org-item {
  display: flex;
  align-items: center;

  img {
    width: 14px;
    margin-left: 3px;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  span {
    line-height: 15px;
    font-size: 13px;
    text-decoration: underline;
  }
}
.my-table {
  margin-top: 15px;
}
.contrainer {
  display: flex;
  .contrainer-left {
    width: 260px;
    margin-right: 15px;
    min-height: calc(100vh - 90px);

    .card {
      height: 100%;
    }
  }
  .contrainer-right {
    flex: 1;
    min-width: 600px;
    min-height: calc(100vh - 90px);
  }
}

.el-tree {
  margin: -10px;
}
// ::v-deep .el-tree {
//   font-size: 14px;
//   .el-tree-node__content {
//     font-size: 14px;
//     height: 34px;
//     line-height: 34px;
//   }
//   .el-tree-node__children {
//     .el-tree-node .el-tree-node__content {
//       font-size: 14px;
//       margin: 4px 0;
//       height: 34px;
//       line-height: 34px;
//       padding-right: 18px;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       max-width: 350px;
//     }
//   }
// }
</style>
